import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Injectable, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { TranslocoService } from '@jsverse/transloco';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { DeepSignal } from '@ngrx/signals/src/deep-signal';
import { ToasterService } from '@shared/components';
import { Tag } from '@shared/types';
import { LocalizeService } from '@trendency/ng-translate';
import { exhaustMap, pipe, throwError } from 'rxjs';

import { AuthService, AuthUserResponse } from '../services/auth.service';

export type AuthStateType = {
  readonly isPasswordMenuShown: boolean;
  readonly isLoggedIn: boolean;
  readonly user: {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly tags: ReadonlyArray<Tag>;
  };
};

const initialState: AuthStateType = {
  isPasswordMenuShown: false,
  isLoggedIn: false,
  user: {
    firstName: '',
    lastName: '',
    tags: [],
    email: '',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AuthState {
  readonly #localizeService = inject(LocalizeService);
  readonly #authService = inject(AuthService);
  readonly #toasterService = inject(ToasterService);
  readonly #router = inject(Router);
  readonly #routesFullPaths = inject(ROUTES_FULL_PATHS);
  readonly #translocoService = inject(TranslocoService);
  readonly #authState = signalState<AuthStateType>(initialState);

  readonly userFullname: Signal<string> = computed(() => `${this.#authState.user.lastName()} ${this.#authState.user.firstName()}`);
  readonly isPasswordMenuShown: Signal<boolean> = this.#authState.isPasswordMenuShown;
  readonly isLoggedIn: Signal<boolean> = this.#authState.isLoggedIn;
  readonly user: DeepSignal<AuthStateType['user']> = this.#authState.user;

  setIsPasswordMenuShown = (value: boolean): void => {
    patchState(this.#authState, (state) => ({ ...state, isPasswordMenuShown: value }));
  };

  setIsLoggedIn = (value: boolean): void => {
    patchState(this.#authState, (state) => ({ ...state, isLoggedIn: value }));
  };

  setUser = (user: AuthUserResponse): void => {
    patchState(this.#authState, (state) => ({
      user: { ...state.user, firstName: user.firstName, lastName: user.lastName, tags: user.tags, email: user.email },
    }));
  };

  reset = (): void => {
    patchState(this.#authState, (state) => ({ ...state, initialState }));
  };

  logout = rxMethod<void>(
    pipe(
      exhaustMap(() => {
        return this.#authService.postLogout$().pipe(
          tapResponse({
            next: () => {
              patchState(this.#authState, (state) => ({ ...state, isLoggedIn: false }));
              this.#toasterService.openToaster({
                title: this.#translocoService.translate('SUCCESS.LOGOUT-TITLE'),
                type: 'success',
                delay: 3000,
              });

              this.#router.navigate([this.#localizeService.translateRouteToCurrentLanguage(this.#routesFullPaths.HOME)]);
            },
            error: (err: HttpErrorResponse) => {
              patchState(this.#authState, (state) => ({ ...state, isLoggedIn: false }));

              this.#toasterService.openToaster({
                title: this.#translocoService.translate('ERROR.LOGOUT-TITLE'),
                type: 'danger',
                delay: 3000,
              });

              return throwError(() => err);
            },
          })
        );
      })
    )
  );
}
